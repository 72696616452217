<template>
    <div>
        <b-modal ref="modal" hide-footer>
            <template v-slot:modal-title>
                <template v-if="ajouter">
                    {{ $t("compta.ajouter_compte_comptable") }}
                </template>

                <template v-if="modifier">
                    {{ $t("compta.modifier_compte_comptable") }}
                </template>
            </template>

            <div v-if="!modal_loaded">
                <LoadingSpinner class="col-12" />
            </div>

            <div v-if="modal_loaded">
                <form @submit.prevent="checkForm">
                    <div class="row">
                        <div class="col-12" v-if="form_message !== ''">
                            <ErrorAlert :messageI18n="form_message" />
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <label for="accountingpaymentaccount_number" class="col-form-label">{{ $t("compta.form.numero_compte") }} *</label>
                                <input type="text" id="accountingpaymentaccount_number" class="form-control" v-model="form.accountingpaymentaccount_number" required>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <label for="accountingaccount_vat" class="col-form-label">{{ $t("payment.payment_method") }} *</label>
                                <e-select
                                    v-model="form.accountingpaymentaccount_paymentmethod"
                                    id="paymentmethod_id"
                                    track-by="paymentmethod_id"
                                    label="paymentmethod_label"
                                    :placeholder="getTrad('payment.payment_method')"
                                    :deselectLabel="deselectLabel"
                                    :selectedLabel="selectedLabel"
                                    :selectLabel="enter_to_select"
                                    :options="payment_methods"
                                    :searchable="true"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    :class="{ 'is-invalid': errors && errors.indexOf('payment method') > -1 }"
								/>
                            </div>
						</div>
                    </div>
                </form>

                <b-button v-if="ajouter" class="mt-3" block @click.prevent="checkForm">{{ $t("global.ajouter") }}</b-button>
                <b-button v-if="modifier" class="mt-3" block @click.prevent="checkForm">{{ $t("global.modifier") }}</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Accounting from "@/mixins/Accounting.js"
    import AccountingAccount from "@/mixins/AccountingAccount.js"
    import Invoice from "@/mixins/Invoice.js"
    import Tools from "@/mixins/Tools.js"

    export default {
        name: "AccountingAccountAjout",
        props: ['accountingplan_id'],
        mixins: [Navigation, Accounting, AccountingAccount, Invoice, Tools],
        data () {
            return {
                deselectLabel: this.getTrad("global.press_enter_to_remove"),
                selectedLabel: this.getTrad("global.selected_label"),
                enter_to_select: this.getTrad("global.press_enter_to_select"),
                labelTitlePaymentMethod: this.getTrad("compta.search_vat"),
                payment_methods: [],
                errors: [],
                modal_loaded: false,
                ajouter: false,
                modifier: false,
                form_message: "",
                form: {},
                default_form: {
                    accountingpaymentaccount_id: '',
                    accountingpaymentaccount_number: '',
                    accountingpaymentaccount_paymentmethod: null
                },
                events_tab: {
                    'TableAction::goToAddAccountingPaymentAccount': () => {
                        this.addOrEdit()
                    },
                    'TableAction::goToEditAccountingPaymentAccount': (params) => {
                        this.addOrEdit(params.accountingpaymentaccount_id)
                    }
                }
            }
        },
        mounted() {
        },
        methods: {
            async addOrEdit(accountingpaymentaccount_id) {
                this.form_message = ""
                this.modal_loaded = false
                this.$refs['modal'].show()

                if(accountingpaymentaccount_id) {
                    const temp_form = this.deppCloneObj(this.$parent.accounting_payment_account).filter(acounting_account => acounting_account.accountingpaymentaccount_id == accountingpaymentaccount_id)[0]

                    temp_form.accountingpaymentaccount_paymentmethod = temp_form.payment_method

                    this.form = temp_form
                    this.ajouter = false
                    this.modifier = true

                } else {
                    this.form = this.deppCloneObj(this.default_form)
                    this.ajouter = true
                    this.modifier = false
                }

                if(this.payment_methods.length == 0) {
                    this.payment_methods = this.getConfig('payment_method')
                }

                this.modal_loaded = true
            },
            checkForm: async function () {
                this.form_message = ""
                this.error = []

                if(!this.form.accountingpaymentaccount_number) {
                    this.error.push("Erreur number")
                }


                if(Object.keys(this.form.accountingpaymentaccount_paymentmethod).length == 0)
                {
                    this.error.push("payment method")
                }

                let result = null
                if(this.error.length == 0) {
                    if(this.form.accountingpaymentaccount_id) {
                        result = await this.editAccountingPaymentAccount(
                            this.form.accountingpaymentaccount_id,
                            this.accountingplan_id,
							this.form.accountingpaymentaccount_number,
                            this.form.accountingpaymentaccount_paymentmethod.paymentmethod_id
                        )
                    } else {
                        result = await this.addAccountingPaymentAccount(
                            this.accountingplan_id,
							this.form.accountingpaymentaccount_number,
							this.form.accountingpaymentaccount_paymentmethod.paymentmethod_id
                        )
                    }

                    if(result) {
                        this.$parent.loadAccountingAccountListe()
                        this.$refs['modal'].hide()
                    } else {
                        this.form_message = "error.LEP"
                    }
                } else {
                    this.form_message = "formulaire.erreur_champs_non_remplis"
                }
            }
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
        }
    }
</script>
